.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card-header{
  height: 60px;
  background-color: rgb(1, 90, 1) !important;
  
  color: white;

}

.card-title{
  color: white !important;
  padding-top: 10px;
  font-size: 17px;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
  font-size:8px;
  text-align: left;
}

.dropbtn {
  background-color: #086c14 !important;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #086c14;
}

.navbar-expand-lg{
  font-size: 14px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-dropdown:hover .nav-link {
  text-decoration: underline 3px #000;
}
.card-title {
    margin-bottom: 0.75rem;
   
}


.card-text-vivo{
  color: #FFF !important;
}

.card-body-vivo{
  color: #FFF !important;
}

.col-sm-4 mb-3{
  background-color: #086c14 !important;
}
.col-sm-4 mb-3{
  max-height: 60px;
}